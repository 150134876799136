import React from 'react'

export default function InputFiels({ errors, register, name, placeholder, type, label, disabled, ...rest }) {
    return (
        <div className="group fields mb-5">
            <label
                className="inline-block pb-1 w-full text-xs font-medium text-balck transition-all duration-200 ease-in-out group-focus-within:text-primary"
            >
                {label}
            </label>
            <div className="relative flex items-center">
                <input
                    name={name}
                    disabled={disabled}
                    {...rest}
                    {...register(name, {
                        required: true,
                        // value: name
                    })}
                    type={type ? type : 'text'}
                    id={name}
                    placeholder={placeholder}
                    className={`relative h-10 text-xs w-full rounded-md ${errors ? 'border border-wow-red' : ''} bg-[#2C2C2E] px-4 outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-[#2C2C2E] focus:ring-2 focus:ring-green focus:drop-shadow-lg`}
                />
            </div>
            {errors && <div className='error-message text-[12px] text-wow-red'>{(errors)}</div>}
            {/* {name === 'user_name' && <p className='text-right text-xs text-[#64EAAA] pt-1'>username is available</p>} */}
        </div>
    )
}
