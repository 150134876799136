import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatSlider } from '../../components/ChatSlider/ChatSlider';
import { LoadingIcon, SearchInputIcon } from '../../assets';

export const AddFriend = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length >= 3) {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/search?search=${value}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setSearchResults(data);
        setLoading(false);
      } catch (error) {
        console.error('Error searching users:', error);
        setLoading(false);
      }
    } else {
      setSearchResults([]);
    }
  };
  console.log(searchResults)
  return (
    <React.Fragment>
      <div>
        <div className='px-4'>
          <div className='pt-4 absolute'>
            <div onClick={() => navigate('/chathome')} className='text-green cursor-pointer font-semibold'>Cancel</div>
          </div>
          <p className='font-semibold pt-4 text-base text-center'>Add Friend</p>
          <div className="group fields pt-5 pb-8">
            <div className="relative flex items-center">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder='3h7x2gf9kq'
                className="relative h-7 text-xs w-full border border-gray-400 rounded-full bg-[#2C2C2E] pr-4 pl-9 outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-[#2C2C2E] focus:ring-1 focus:ring-green focus:drop-shadow-lg"
              />
              <span className="absolute left-[10px] transition-all duration-200 ease-in-out group-focus-within:text-primary">
                <SearchInputIcon />
              </span>
            </div>
          </div>
        </div>

        <div className='list-wrapper mb-8'>
          {!loading && searchResults?.user?.length > 0 ? (
            <div className="search-results">
              {searchResults.user?.map((user, index) => (
                <div key={index}>
                  <ChatSlider user={user} />
                </div>
              ))}
            </div>
          ) : loading ? (
            <div className="flex items-center justify-center mt-[40%]">
              <LoadingIcon />
            </div>
          ) : (
            <div className="flex text-sm items-center font-semibold justify-center mt-[40%]">
              No user found
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}
