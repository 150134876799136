import React from 'react';
import { useNavigate } from 'react-router-dom';
import dots from "../../images/dots.png";

export const ChatHeader = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <main>
        <div className='pt-4'>
          <button onClick={() => navigate('/settings')} className='cursor-pointer flex justify-center items-center font-semibold bg-[#2C2C2C] rounded-full w-5 h-5'>
            <img src={dots} className='w-3' alt='Media' />
          </button>
        </div>
        <div className='flex justify-between items-center pt-3'>
          <p className='text-base text-green font-semibold'>Chat</p>
          <div onClick={() => navigate('/contacts')} className='cursor-pointer flex justify-center items-center bg-green rounded-full w-5 h-5'>
            <svg
              className='w-3'
              viewBox="0 0 512 512"
            >
              <path d="M417.4,224H288V94.6c0-16.9-14.3-30.6-32-30.6c-17.7,0-32,13.7-32,30.6V224H94.6C77.7,224,64,238.3,64,256  c0,17.7,13.7,32,30.6,32H224v129.4c0,16.9,14.3,30.6,32,30.6c17.7,0,32-13.7,32-30.6V288h129.4c16.9,0,30.6-14.3,30.6-32  C448,238.3,434.3,224,417.4,224z" />
            </svg>
          </div>
        </div>

      </main>
    </React.Fragment>
  );
};
