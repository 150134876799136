import { takeLatest, call, put } from "redux-saga/effects";
import {
    checkUserExistsStart,
    checkUserExistsSuccess,
    checkUserExistsFailure,
} from "../reducers/userSlice";

const checkUserExistsAPI = async (walletAddress) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/check-user-exists/${walletAddress}`);
    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to check if user exists");
    }
    return await response.json();
};

function* handleCheckUserExists(action) {
    try {
        const result = yield call(checkUserExistsAPI, action.payload); // Call API with the wallet address
        yield put(checkUserExistsSuccess(result)); // Dispatch success action with API result
    } catch (error) {
        yield put(checkUserExistsFailure(error.message)); // Dispatch failure action with error message
    }
}

export function* userCheckSaga() {
    yield takeLatest(checkUserExistsStart.type, handleCheckUserExists); // Watch for the checkUserExistsStart action
}
