import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SearchField } from '../../components/SearchField/SearchField';
import { ChatHeader } from '../../components/ChatHeader/ChatHeader';
import { fetchChatsStart, fetchContactListStart, fetchProfileStart } from '../../redux/reducers/profileSettingSlice';
import { ChatList } from '../ChatList/ChatList';
import { ChatSlider } from '../../components/ChatSlider/ChatSlider';

export const ChatHome = () => {
  const dispatch = useDispatch();
  const { chats, loadingChats } = useSelector(state => state.profileSetting);
  console.log(chats)
  useEffect(() => {
    dispatch(fetchProfileStart());
    dispatch(fetchChatsStart());
    dispatch(fetchContactListStart());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className='main-wrapper'>
        <ChatHeader />
        <SearchField />
        {chats?.length > 0 ?
          <>
            <ChatSlider />
          </>
          :
          <div className='text-gray-400 text-base text-center h-scale flex justify-center items-center'>
            No conversations yet
          </div>
        }
      </div>
    </React.Fragment>
  )
}
