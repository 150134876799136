import { takeLatest, call, put } from "redux-saga/effects";
import { initiateSignin, signinSuccess, signinFailure, checkUsernameSuccess, checkUsernameFailure, checkUsername } from "../reducers/auth";

const createUser = async (payload) => {
    const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/auth/authenticate`,
        {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                walletAddress: payload.walletAddress,
                user_device_id: payload.user_device_id,
            }),
        }
    );
    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to authenticate user");
    }
    const data = await response.json();
    // Store token securely (e.g., localStorage, sessionStorage, or cookies)
    if (data.accessToken) {
        localStorage.setItem("authToken", data.accessToken); // Save token
    }
    return data;
};

function* userSignIn(action) {
    try {
        const userData = yield call(createUser, action.payload);
        yield put(signinSuccess(userData)); // Dispatch success action
    } catch (error) {
        localStorage.removeItem("authToken");
        yield put(signinFailure(error.message)); // Dispatch failure action
    }
}

export function* initiateSignInSaga() {
    yield takeLatest(initiateSignin.type, userSignIn);
}


//Check User Name
const checkUsernameAPI = async (username) => {
    const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/auth/check-username?username=${username}`
    );

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to check username availability");
    }

    return await response.json();
};

function* handleCheckUsername(action) {
    try {
        const result = yield call(checkUsernameAPI, action.payload); // Call API with the username
        yield put(checkUsernameSuccess(result)); // Dispatch success action with API result
    } catch (error) {
        yield put(checkUsernameFailure(error.message)); // Dispatch failure action with error message
    }
}

export function* usernameCheckSaga() {
    yield takeLatest(checkUsername.type, handleCheckUsername); // Watch for the checkUsername action
}