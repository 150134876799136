import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import avatar from "../../images/avatar.png";
import logo from "../../images/logo.svg";
import allen from "../../images/allen.svg";
import user from "../../images/user.svg";
import offline from "../../images/offline.svg";
import { SearchField } from '../../components/SearchField/SearchField';
import { BroadcastIcon, CommunityIcon, ContactIcon, GroupIcon } from '../../assets';

export const Contacts = () => {
  const navigate = useNavigate();
  const { contactList } = useSelector(state => state.profileSetting);

  return (
    <React.Fragment>
      <div className='main-wrapper'>
        <div className='pt-4 absolute'>
          <div onClick={() => navigate('/chathome')} className='text-green cursor-pointer font-semibold'>Cancel</div>
        </div>
        <p className='font-semibold pt-4 text-base text-center'>Contacts</p>
        <div className="">
          <SearchField />
        </div>

        {/* <div onClick={() => navigate('/addfriend')} className='flex gap-2 min-h-10 mb-4 p-3 rounded-[10px] w-full bg-dark-gray text-white text-base'>
          Add friend
        </div> */}
        <div className='bg-dark-gray py-3 pl-4 rounded-[10px] mb-5'>
          <div onClick={() => navigate('/addfriend')} className='cursor-pointer pb-3 flex gap-3 items-start'>
            <div className='relative w-9'>
              <GroupIcon />
            </div>
            <div className='border-b border-gray-600 w-full pb-4'>
              <p className='text-sm pt-[6px]'>New group</p>
            </div>
          </div>

          <div className='cursor-pointer pb-3 flex gap-3 items-start'>
            <div className='relative w-9'>
              <ContactIcon />
            </div>
            <div className='border-b border-gray-600 w-full pb-4'>
              <p className='text-sm pt-[6px]'>New contact</p>
            </div>
          </div>

          <div className='cursor-pointer pb-3 flex gap-3 items-start'>
            <div className='relative w-9'>
              <CommunityIcon />
            </div>
            <div className='border-b border-gray-600 w-full pb-4'>
              <p className='text-sm pt-[6px]'>New community</p>
            </div>
          </div>

          <div className='cursor-pointer flex gap-3 items-center'>
            <div className='relative w-9 flex justify-center'>
              <BroadcastIcon />
            </div>
            <div className='w-full pb-4'>
              <p className='text-sm pt-[6px]'>New broadcast</p>
            </div>
          </div>
        </div>
        <p className='text-base mb-5'>Contact list</p>

        <div className='list-wrapper mb-8'>
          <p className='text-base mb-1 ml-2'>A</p>
          <div className='bg-dark-gray p-4 rounded-[10px]'>
            <Link href='' className='py-3 flex gap-3 items-start'>
              <div className='relative w-9'>
                <img src={avatar} className='w-9 rounded-full' />
                <div className='absolute w-3 right-0 bottom-0'>
                  <img src={logo} className='w-full rounded-full' />
                </div>
              </div>
              <div className='border-b border-gray-600 w-full pb-4'>
                <p className='text-sm pt-[6px]'>Albort</p>
              </div>
            </Link>

            <Link href='' className='pb-3 flex gap-3 items-start'>
              <div className='relative w-9'>
                <img src={allen} className='w-9 rounded-full' />
                <div className='absolute w-3 right-0 bottom-0'>
                  <img src={logo} className='w-full rounded-full' />
                </div>
              </div>
              <div className='border-b border-gray-600 w-full pb-4'>
                <p className='text-sm pt-[6px]'>Allen</p>
              </div>
            </Link>

            <Link href='' className='pb-3 flex gap-3 items-start'>
              <div className='relative w-9'>
                <img src={user} className='w-9 rounded-full' />
                <div className='absolute w-3 right-0 bottom-0'>
                  <img src={offline} className='w-full rounded-full' />
                </div>
              </div>
              <div className='border-b border-gray-600 w-full pb-4'>
                <p className='text-sm pt-[6px]'>Andrew</p>
              </div>
            </Link>

            <Link href='' className='pb-3 flex gap-3 items-start'>
              <div className='relative w-9'>
                <img src={user} className='w-9 rounded-full' />
                <div className='absolute w-3 right-0 bottom-0'>
                  <img src={logo} className='w-full rounded-full' />
                </div>
              </div>
              <div className='border-b border-gray-600 w-full pb-4'>
                <p className='text-sm pt-[6px]'>Alson</p>
              </div>
            </Link>

            <Link href='' className='pb-3 flex gap-3 items-start'>
              <div className='relative w-9'>
                <img src={user} className='w-9 rounded-full' />
                <div className='absolute w-3 right-0 bottom-0'>
                  <img src={logo} className='w-full rounded-full' />
                </div>
              </div>
              <div className='border-b border-gray-600 w-full pb-4'>
                <p className='text-sm pt-[6px]'>Abraham</p>
              </div>
            </Link>

            <Link href='' className='pb-3 flex gap-3 items-start'>
              <div className='relative w-9'>
                <img src={user} className='w-9 rounded-full' />
                <div className='absolute w-3 right-0 bottom-0'>
                  <img src={logo} className='w-full rounded-full' />
                </div>
              </div>
              <div className='border-b border-gray-600 w-full pb-4'>
                <p className='text-sm pt-[6px]'>Asonk</p>
              </div>
            </Link>
          </div>
        </div>

        <div className='list-wrapper mb-8'>
          <p className='text-base mb-1 pl-2'>B</p>
          <div className='bg-dark-gray p-4 rounded-[10px]'>
            <Link href='' className='flex gap-3 items-start'>
              <div className='relative w-9'>
                <img src={user} className='w-9 rounded-full' />
                <div className='absolute w-3 right-0 bottom-0'>
                  <img src={logo} className='w-full rounded-full' />
                </div>
              </div>
              <div className='border-b border-gray-600 w-full pb-4'>
                <p className='text-sm pt-[6px]'>Benz</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
