"use client";
import React, { useState, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import logo from "../../images/logo.svg";
import colorCopy from "../../images/color-copy.svg";
import copyText from "../../images/copy-text.svg";
import copyText2 from "../../images/copy-text2.svg";
import blackcopy from "../../images/blackcopy.svg";
import cross from "../../images/cross.svg";
import download from "../../images/download.svg";
import william from "../../images/william.svg";
import share from "../../images/share.svg";
import InviteModal from '../InviteModal/InviteModal';

export default function QrModal({ setShowQrModal, profile }) {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copiedID, setCopiedID] = useState(false);
  const qrRef = useRef(null);
  const inviteLink = `https://yourapp.com/invite?ref=${profile?.uid}`;

  const handleDownload = () => {
    const canvas = qrRef.current?.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL('image/png');
      const blob = fetch(url).then(res => res.blob());
      blob.then(blobData => {
        const objectUrl = window.URL.createObjectURL(blobData);
        window.location.href = objectUrl;
        window.URL.revokeObjectURL(objectUrl);
      });
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(inviteLink);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
    // You can add a toast notification here
  };

  const handleCopyId = () => {
    navigator.clipboard.writeText(profile?.uid);
    setCopiedID(true);
    setTimeout(() => {
      setCopiedID(false);
    }, 5000);
    // You can add a toast notification here
  };
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Connect with me',
          text: 'Scan this QR code or use this link to connect with me',
          url: inviteLink
        });
      } catch (err) {
        // setShowInviteModal(true);
      }
    } else {
      // setShowInviteModal(true);
    }
  };

  return (
    <React.Fragment>
      {showInviteModal && (<InviteModal setShowInviteModal={setShowInviteModal} />)}

      {/* QR Modal Starts Here */}
      <div className='app-modal'>
        <div
          className="wallet-wrapper justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none"
        >
          <div className="relative w-full h-full">
            {/*content*/}
            <div className="mt-10 relative md:relative h-screen md:h-auto bottom-0 md:bottom-0 max-[768px]:w-full mx-auto border-0 rounded-tl-[30px] rounded-tr-[30px] md:rounded-[30px] flex flex-col w-[450px] bg-dark-gray outline-none focus:outline-none">
              {/*header*/}
              <button className='close-modal absolute right-[20px] top-[12px]' onClick={() => setShowQrModal(false)}>
                <img src={cross} className='w-6' alt='Media' />
              </button>
              <div className='modal-content rounded-2xl px-[20px] py-3 mt-4'>
                <div className='mt-4 mb-16'>
                  <h2 className='text-xl text-center font-semibold'>QR Code</h2>
                </div>
                <div className='mb-6'>
                  <div ref={qrRef} className='relative rounded-[32px] canvas-wrapper bg-green px-4 py-8'>
                    <div className='relative'>
                      <QRCodeCanvas value={inviteLink} size={300} level='M' />
                      <img src={logo} className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 right-0 w-8 rounded-full' />
                    </div>,
                    {/* <div ref={qrRef} className='relative rounded-[32px] canvas-wrapper bg-green px-4 py-8'>
                    <QRCodeCanvas value={inviteLink} size={300} level='M' /> */}
                    <div className='flex justify-center items-center flex-col gap-2'>
                      <div className='absolute -top-8 w-[60px]'>
                        <img src={profile?.avatar || william} className='w-[60px] rounded-full' />
                        <img src={logo} className='absolute bottom-0 right-0 w-5 rounded-full' />
                      </div>
                    </div>
                    <div onClick={handleCopyId} className='flex gap-2 items-center justify-center -mt-4'>
                      <img src={copiedID ? copyText2 : blackcopy} className='w-4' />
                      <p className='text-base text-black font-bold'>{profile?.uid?.split('-').pop()}</p>
                    </div>
                  </div>

                  <div className='flex justify-center items-center gap-3 my-5'>
                    <button onClick={handleDownload} className='rounded-lg flex flex-col justify-center items-center gap-[6px] py-3 px-6 bg-icon'>
                      <img src={download} className='w-5' alt='Media' />
                      <p className='text-xs text-white text-center'>Save</p>
                    </button>

                    <button onClick={handleShare} className='rounded-lg flex flex-col justify-center items-center gap-[6px] py-3 px-6 bg-icon'>
                      <img src={share} className='w-5' alt='Media' />
                      <p className='text-xs text-white text-center'>Share</p>
                    </button>
                  </div>

                  <p className='text-xs text-gray-400 my-5 text-center'>
                    Only share your QR code and link with people you trust.
                    When shared others will be able to view your username and start a chat with you.
                  </p>
                  <div onClick={handleCopy} className='p-3 rounded-xl border-2 border-wow-green flex items-center gap-3 cursor-pointer'>
                    <img src={copied ? copyText : colorCopy} className='w-6' alt='Media' />
                    <p className='text-wow-green text-xs break-all'>
                      {inviteLink}
                    </p>
                  </div>
                </div>
                {/*body*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* QR Modal Ends Here */}
    </React.Fragment>
  )
}
