import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from 'react-router-dom';
import { checkUsername } from '../../redux/reducers/auth';
import InputFiels from '../../components/InputField/InputFiels';
import { CamraIcon, LoadingIcon } from "../../assets/index";
import CropModal from '../../components/ImageCrop/CropperModal'; // Import CropModal
import { updateProfileStart } from '../../redux/reducers/userProfileSlice';

const schema = yup.object().shape({
  user_name: yup.string().required("User Name is required."),
  user_id: yup.string().required("User ID is required."),
});

export const Username = () => {
  const [profileImage, setProfileImage] = useState(null); // Final cropped image
  const [selectedImage, setSelectedImage] = useState(null); // Original uploaded image
  const [cropModalOpen, setCropModalOpen] = useState(false); // Modal open state

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { user_id: "xyz", user_name: "" },
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const username = watch("user_name");
  const { loading, updating, success, error } = useSelector((state) => state.profile);
  const { usernameAvailability, usernameCheckLoading, checkUserError } = useSelector((state) => state.auth);

  useEffect(() => {
    const UserID = window?.clientInfo?.externalId;
    if (UserID) {
      setValue("user_name", UserID);
    }
  }, [])

  useEffect(() => {
    if(success){
      navigate('/chathome')
    }
  }, [success])

  useEffect(() => {
    if (username?.length > 3) {
      dispatch(checkUsername(username));
    }
  }, [username])

  const handleSubmitForm = (data) => {
    const formData = new FormData();
    // formData.append('displayName', data?.user_name);
    // formData.append('avatar', profileImage);
    // console.log('FormData contents:', formData);
    // formData.forEach((value, key) => {
    //     console.log(`${key}:`, value);
    // });

    dispatch(updateProfileStart({displayName: data?.user_name, 'avatar': profileImage}));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result); // Load selected image for cropping
        setCropModalOpen(true); // Open crop modal
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropSave = (croppedImage) => {
    setProfileImage(croppedImage); // Save cropped image
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className='main-wrapper'>
          <div className='pt-4 absolute'>
            <a href='/' className='text-green cursor-pointer font-semibold'>Cancel</a>
          </div>
          <p className='font-semibold pt-4 text-base text-center'>Add Profile Picture</p>

          <div className='flex flex-col items-center pt-8 justify-center gap-2'>
            <div className='relative'>
              <label
                htmlFor="profile-image-upload"
                className='rounded-full w-[105px] h-[105px] cursor-pointer bg-[#1C1C1E] flex items-center justify-center'
              >
                {profileImage ? (
                  <img
                    src={profileImage}
                    alt="Profile Preview"
                    className="rounded-full w-[105px] h-[105px] object-cover"
                  />
                ) : (
                  <CamraIcon />
                )}
              </label>
              <input
                type="file"
                id="profile-image-upload"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
              />
            </div>
            <p className='text-xs font-semibold text-center'>Add Profile picture</p>
          </div>

          <p className='text-sm text-center pt-10 pb-6'>
            You can choose a username to help others more easily find and reach you on wow
            {error && <div className='error-message text-[12px] text-wow-red'>{(error)}</div>}
          </p>
          <>
            <InputFiels
              register={register}
              errors={errors?.user_name?.message}
              name="user_name"
              placeholder="Enter your User Name"
              type="text"
              label={"User Name"}
            />
            {usernameAvailability && <p className='text-right text-xs text-[#64EAAA]'>username is available</p>}
            {checkUserError && <div className='text-right text-xs text-wow-red'>{(checkUserError)}</div>}
          </>
          <InputFiels
            register={register}
            errors={errors?.user_id?.message}
            name="user_id"
            placeholder="Enter your User ID"
            type="text"
            disabled={true}
            label={"User ID"}
          />
          {loading ?
            <button type='button' className='text-center block min-h-11 mt-9 mb-3 p-3 rounded-lg w-full bg-btn hover:bg-hover text-white text-base font-medium'>
              <LoadingIcon />
            </button> :
            <button type='submit' className='text-center block min-h-11 mt-9 mb-3 p-3 rounded-lg w-full bg-btn text-white text-base font-medium'>
              Create
            </button>
          }
        </div>
      </form>

      <CropModal
        open={cropModalOpen}
        onClose={() => setCropModalOpen(false)}
        imageToCrop={selectedImage}
        onCropSave={handleCropSave}
      />
    </React.Fragment>
  );
};
