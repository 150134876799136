import { takeLatest, call, put } from "redux-saga/effects";
import { fetchContactListFailure, fetchContactListStart, fetchContactListSuccess, fetchProfileFailure, fetchProfileStart, fetchProfileSuccess, fetchChatsSuccess, fetchChatsStart, fetchChatsFailure } from "../reducers/profileSettingSlice";

const fetchProfileAPI = async () => {
    const token = localStorage.getItem("authToken");
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/user-profile`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to fetch user profile");
    }
    return await response.json();
};

function* handleFetchProfile() {
    try {
        const profileData = yield call(fetchProfileAPI); // Fetch profile data
        yield put(fetchProfileSuccess(profileData)); // Dispatch success action with profile data
    } catch (error) {
        yield put(fetchProfileFailure(error.message)); // Dispatch failure action
    }
}

export function* profileSettingSaga() {
    yield takeLatest(fetchProfileStart.type, handleFetchProfile);
    // Add other watchers like updateProfileStart.type if not already added
}

export const fetchContactListAPI = async () => {
    const token = localStorage.getItem("authToken"); // Assuming the token is stored in localStorage
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/contact-list`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`, // Pass the token in the header
        },
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to fetch contact list");
    }

    return await response.json();
};
function* handleFetchContactList() {
    try {
        const contactListData = yield call(fetchContactListAPI); // Call the API to get contact list
        yield put(fetchContactListSuccess(contactListData)); // Dispatch success action with contact list data
    } catch (error) {
        yield put(fetchContactListFailure(error.message)); // Dispatch failure action if there's an error
    }
}

export function* contactListSaga() {
    yield takeLatest(fetchContactListStart.type, handleFetchContactList); // Watch for the `fetchContactListStart` action
}

export const fetchChatsAPI = async () => {
    const token = localStorage.getItem("authToken"); // Assuming the token is stored in localStorage
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chats`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`, // Include token in the request headers
        },
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to fetch chats");
    }

    return await response.json(); // Return the data from the API
};

function* handleFetchChats() {
    try {
        const chatData = yield call(fetchChatsAPI); // Call the API to get chats
        yield put(fetchChatsSuccess(chatData)); // Dispatch success action with chat data
    } catch (error) {
        yield put(fetchChatsFailure(error.message)); // Dispatch failure action if there's an error
    }
}

export function* chatsSaga() {
    yield takeLatest(fetchChatsStart.type, handleFetchChats); // Watch for the `fetchChatsStart` action
}