import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updating: false, // To track loading state
    success: false,  // To track success of update
    error: null,     // To store any errors from the API
};

export const profileSlice = createSlice({
    name: "profileReducer",
    initialState,
    reducers: {
        updateProfileStart: (state) => {
            state.updating = true;
            state.success = false;
            state.error = null;
        },
        updateProfileSuccess: (state) => {
            state.updating = false;
            state.success = true;
        },
        updateProfileFailure: (state, action) => {
            state.updating = false;
            state.error = action.payload; // Store error message
        },
        resetProfileState: (state) => {
            state.updating = false;
            state.success = false;
            state.error = null;
        },
    },
});

export const {
    updateProfileStart,
    updateProfileSuccess,
    updateProfileFailure,
    resetProfileState,
} = profileSlice.actions;

export default profileSlice.reducer;
