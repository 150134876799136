"use client";
import React from 'react';
import logo from "../../images/logo.svg";
import mute from "../../images/mute.svg";
import cross from "../../images/cross.svg";
import block from "../../images/block.svg";
import deleteicon from "../../images/deleteicon.svg";
import copy from "../../images/copy.svg";
import Ananya from "../../images/Ananya.svg";
import MuteModal from '../MuteModal/MuteModal';
import BlockModal from '../BlockModal/BlockModal';
import DeleteModal from '../DeleteModal/DeleteModal';


export default function MoreModal({ setShowMoreModal }) {
  const [showMuteModal, setShowMuteModal] = React.useState(false);
  const [showBlockModal, setShowBlockModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  return (
    <React.Fragment>
      {showMuteModal && (<MuteModal setShowMuteModal={setShowMuteModal} />)}
      {showBlockModal && (<BlockModal setShowBlockModal={setShowBlockModal} />)}
      {showDeleteModal && (<DeleteModal setShowDeleteModal={setShowDeleteModal} />)}

      {/* More Modal Starts Here */}
      <div className='app-modal'>
        <div
          className="wallet-wrapper justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-full h-full">
            {/*content*/}
            <div className="mt-10 absolute md:relative bottom-0 max-[768px]:w-full mx-auto border-0 rounded-tl-[30px] rounded-tr-[30px] md:rounded-[30px] flex flex-col w-[450px] bg-dark-gray outline-none focus:outline-none">
              {/*header*/}
              <button className='close-modal absolute right-[20px] top-[12px]' onClick={() => setShowMoreModal(false)}>
                <img src={cross} className='w-6' alt='Media' />
              </button>
              <div className='modal-content rounded-2xl px-[20px] py-3 mt-4'>
                <div className='mt-4 mb-8'>
                  <div className='flex gap-2'>
                    <div className='relative min-w-9 max-w-9'>
                      <img src={Ananya} className='min-w-9 max-w-9 rounded-full' />
                      <div className='absolute w-3 right-0 bottom-0'>
                        <img src={logo} className='w-full rounded-full' />
                      </div>
                    </div>
                    <div className='w-full'>
                      <p className='text-sm text-green'>Ananya</p>
                      <p className='text-[10px] text-gray-400'>3h7x2gf9kq</p>
                    </div>
                  </div>
                </div>
                <div className='mb-6'>

                  <button onClick={() => setShowMuteModal(true)} className='mb-4 w-full bg-more-gray rounded-[10px] p-4 flex justify-between items-center'>
                    <p className='text-base text-white'>Mute</p>
                    <img src={mute} className='min-w-6 max-w-6' alt='Media' />
                  </button>

                  <button onClick={() => setShowBlockModal(true)} className='mb-4 w-full bg-more-gray rounded-[10px] p-4 flex justify-between items-center'>
                    <p className='text-base text-white'>Block</p>
                    <img src={block} className='min-w-6 max-w-6' alt='Media' />
                  </button>

                  <button onClick={() => setShowDeleteModal(true)} className='mb-4 w-full bg-more-gray rounded-[10px] p-4 flex justify-between items-center'>
                    <p className='text-base text-[#ff0000]'>Delete chat</p>
                    <img src={deleteicon} className='min-w-6 max-w-6' alt='Media' />
                  </button>

                </div>
                {/*body*/}

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* More Modal Ends Here */}
    </React.Fragment>
  )
}
