export const AddFriendIcon = ({ handleCopy }) => {
  return (
    <svg
      className="w-4"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"

    >
      <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
      <circle cx="8.5" cy={7} r={4} />
      <line x1={20} x2={20} y1={8} y2={14} />
      <line x1={23} x2={17} y1={11} y2={11} />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      className='w-5'
      fill='white'
      viewBox="0 0 512 512"
    >
      <path d="M344.5,298c15-23.6,23.8-51.6,23.8-81.7c0-84.1-68.1-152.3-152.1-152.3C132.1,64,64,132.2,64,216.3  c0,84.1,68.1,152.3,152.1,152.3c30.5,0,58.9-9,82.7-24.4l6.9-4.8L414.3,448l33.7-34.3L339.5,305.1L344.5,298z M301.4,131.2  c22.7,22.7,35.2,52.9,35.2,85c0,32.1-12.5,62.3-35.2,85c-22.7,22.7-52.9,35.2-85,35.2c-32.1,0-62.3-12.5-85-35.2  c-22.7-22.7-35.2-52.9-35.2-85c0-32.1,12.5-62.3,35.2-85c22.7-22.7,52.9-35.2,85-35.2C248.5,96,278.7,108.5,301.4,131.2z" />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.34 9.322C16.4361 9.23244 16.5136 9.12484 16.568 9.00533C16.6225 8.88583 16.6529 8.75676 16.6575 8.62551C16.6621 8.49425 16.6408 8.36337 16.5948 8.24035C16.5488 8.11733 16.4791 8.00457 16.3895 7.9085C16.2999 7.81244 16.1923 7.73496 16.0728 7.68049C15.9533 7.62601 15.8243 7.5956 15.693 7.59101C15.5618 7.58641 15.4309 7.60771 15.3079 7.6537C15.1848 7.69968 15.0721 7.76944 14.976 7.859L12.05 10.587L9.32201 7.66C9.13948 7.47305 8.89102 7.36496 8.6298 7.35887C8.36859 7.35278 8.11536 7.44917 7.92431 7.62741C7.73327 7.80565 7.61957 8.05159 7.60755 8.3126C7.59553 8.57361 7.68615 8.82896 7.86001 9.024L10.588 11.95L7.66101 14.678C7.56155 14.7667 7.48082 14.8743 7.42354 14.9946C7.36626 15.1149 7.3336 15.2454 7.32747 15.3785C7.32135 15.5116 7.34188 15.6446 7.38786 15.7696C7.43384 15.8946 7.50434 16.0092 7.59523 16.1067C7.68612 16.2041 7.79555 16.2824 7.91711 16.3369C8.03867 16.3914 8.16991 16.4211 8.3031 16.4242C8.4363 16.4273 8.56877 16.4038 8.69274 16.355C8.81671 16.3062 8.92968 16.2331 9.02501 16.14L11.951 13.413L14.679 16.339C14.7671 16.4403 14.8747 16.5228 14.9953 16.5816C15.116 16.6405 15.2472 16.6744 15.3813 16.6814C15.5153 16.6885 15.6494 16.6684 15.7756 16.6225C15.9017 16.5766 16.0173 16.5058 16.1155 16.4142C16.2137 16.3227 16.2924 16.2123 16.3471 16.0897C16.4017 15.9671 16.4311 15.8347 16.4334 15.7005C16.4358 15.5663 16.4112 15.433 16.3609 15.3085C16.3107 15.1841 16.2359 15.071 16.141 14.976L13.414 12.05L16.34 9.322Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 5.925 5.925 1 12 1C18.075 1 23 5.925 23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12ZM12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 10.8181 3.23279 9.64778 3.68508 8.55585C4.13738 7.46392 4.80031 6.47177 5.63604 5.63604C6.47177 4.80031 7.46392 4.13738 8.55585 3.68508C9.64778 3.23279 10.8181 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21Z"
        fill="white"
      />
    </svg>
  );
};

export const CamraIcon = () => {
  return (
    <svg
      className='w-6'
      fill='white'
      viewBox="0 0 24 24"
    >
      <g>
        <path d="M19,20H5c-1.654,0-3-1.346-3-3V9c0-1.654,1.346-3,3-3h1.586l1-1C8.165,4.421,9.182,4,10,4h4c0.818,0,1.835,0.421,2.414,1   l1,1H19c1.654,0,3,1.346,3,3v8C22,18.654,20.654,20,19,20z M5,8C4.448,8,4,8.448,4,9v8c0,0.552,0.448,1,1,1h14c0.552,0,1-0.448,1-1   V9c0-0.552-0.448-1-1-1h-2c-0.266,0-0.52-0.105-0.707-0.293L15,6.414C14.799,6.213,14.285,6,14,6h-4C9.715,6,9.201,6.213,9,6.414   L7.707,7.707C7.52,7.895,7.266,8,7,8H5z" />
      </g>
      <g>
        <path d="M12,10c1.379,0,2.5,1.121,2.5,2.5S13.379,15,12,15s-2.5-1.121-2.5-2.5S10.621,10,12,10 M12,9c-1.934,0-3.5,1.566-3.5,3.5   c0,1.932,1.566,3.5,3.5,3.5s3.5-1.568,3.5-3.5C15.5,10.566,13.934,9,12,9z" />
      </g>
      <g>
        <path d="M18,8.699c-0.719,0-1.3,0.582-1.3,1.301s0.581,1.299,1.3,1.299s1.3-0.58,1.3-1.299S18.719,8.699,18,8.699z" />
      </g>
    </svg>
  )
}

export const LoadingIcon = ({ height }) => {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        className={`inline ${height ? ` h-${height} w-${height}` : `w-6 h-6 `
          } text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300`}
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const DownIcon = () => {
  return (
    <svg className='w-8' fill='white' viewBox="0 0 32 32">
      <g data-name="Layer 2" id="Layer_2">
        <path
          className="cls-1"
          d="M16.47,16.88,26.34,7a1,1,0,0,0-1.41-1.41l-9.06,9.06-8.8-8.8a1,1,0,0,0-1.41,0h0a1,1,0,0,0,0,1.42l9.61,9.61A.85.85,0,0,0,16.47,16.88Z"
        />
        <path
          className="cls-1"
          d="M16.47,26.46l9.87-9.88a1,1,0,0,0-1.41-1.41l-9.06,9.06-8.8-8.8a1,1,0,0,0-1.41,0h0a1,1,0,0,0,0,1.41l9.61,9.62A.85.85,0,0,0,16.47,26.46Z"
        />
      </g>
    </svg>
  )
}
export const EditIcon = () => {
  return (
    <svg
      height={18}
      fill="white"
      viewBox="0 0 24 24"
      width={18}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z" />
      <path d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z" />
    </svg>
  )
}
export const TickIcon = () => {
  return (
    <svg
      width={16}
      height={15}
      x={2063}
      y={2076}
    >
      <path
        d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
        fill="#4fc3f7"
      />
    </svg>
  );
};

export const MediaIcon = () => {
  return (
    <svg
      className="w-9"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
    >
      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
      <polyline points="14 2 14 8 20 8" />
      <line x1={16} x2={8} y1={13} y2={13} />
      <line x1={16} x2={8} y1={17} y2={17} />
      <polyline points="10 9 9 9 8 9" />
    </svg>
  );
};

export const PlusIcon = () => {
  return (
    <svg
      className='w-[18px]'
      fill='white'
      viewBox="0 0 32 32"
    >
      <path d="M28,14H18V4c0-1.104-0.896-2-2-2s-2,0.896-2,2v10H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h10v10c0,1.104,0.896,2,2,2  s2-0.896,2-2V18h10c1.104,0,2-0.896,2-2S29.104,14,28,14z" />
    </svg>
  );
};
export const BackIcon = () => {
  return (
    <svg className='w-[18px]' fill='#47FDCA' viewBox="0 0 96 96">
      <path d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z" />
    </svg>
  );
};
export const BroadcastIcon = () => {
  return (
    <svg
      viewBox="0 0 52 52"
      className="w-6"
      enableBackground="new 0 0 52 52"
    >
      <path
        d="m42.1 2.801-.242 40.046 1.921-.795c-5.401-5.26-9.104-9.216-16.788-10.382-3.62-.548-7.307-.802-10.962-.975-2.77-.13-6.25.466-8.536-1.428-1.753-1.452-3.421-4.297-3.606-6.518-.872-10.462 17.123-6.235 23.353-8.409 3.035-1.059 6.338-2.527 8.98-4.358 2.748-1.904 4.368-4.685 7.306-6.565 1.216-.779.089-2.727-1.136-1.943-3.24 2.074-5.388 5.19-8.52 7.26-5.018 3.316-10.111 4.243-15.984 4.35-5.11.094-15.857-.145-16.454 6.87C.934 25.796 4.818 31.96 10.73 32.76c5.711.772 11.513.252 17.233 1.327 6.219 1.168 9.88 5.327 14.224 9.556.698.679 1.915.186 1.92-.796l.241-40.046c.009-1.448-2.241-1.45-2.25 0z"
        fill="#47fdca"
        className="fill-231f20"
      />
      <path
        d="M16.804 14.301v16.833c0 1.448 2.25 1.45 2.25 0V14.301c0-1.448-2.25-1.45-2.25 0zM43.543 16.993c2.351.472 4.537 3.045 4.9 5.308.45 2.816-1.242 5.85-4.339 5.962-1.444.053-1.45 2.303 0 2.25 9.099-.332 8.23-14.043.038-15.69-1.414-.284-2.02 1.884-.599 2.17zM12.4 32.218c.08 2.836.245 5.661.56 8.482.097.861.307 1.738.324 2.6.557 1.319.206 2.413-1.052 3.283-.876 2.337-1.656 2.338-2.34.005-.731-2.11-.353-5.354-.501-7.594l-.602-9.112c-.095-1.437-2.345-1.448-2.25 0 .366 5.532.396 11.208 1.103 16.706.298 2.316 1.187 3.805 3.681 4.082 2.32.258 4.463-.771 4.737-3.226.213-1.9-.538-4.107-.764-5.976-.371-3.073-.56-6.157-.645-9.25-.04-1.445-2.291-1.451-2.25 0z"
        fill="#47fdca"
        className="fill-231f20"
      />
    </svg>
  );
};
export const CommunityIcon = () => {
  return (
    <svg
      viewBox="0 0 500 500"
      className="w-9"
    >
      <path
        d="M113.6 311c-30.2 0-54.8-24.6-54.8-54.8 0-30.2 24.6-54.8 54.8-54.8s54.8 24.6 54.8 54.8c0 30.3-24.6 54.8-54.8 54.8zm0-82.1c-15.1 0-27.4 12.3-27.4 27.4 0 15.1 12.3 27.4 27.4 27.4s27.4-12.3 27.4-27.4c0-15.1-12.3-27.4-27.4-27.4zM346.3 365.8h-27.4c0-37.7-30.7-68.4-68.4-68.4s-68.4 30.7-68.4 68.4h-27.4c0-52.8 43-95.8 95.8-95.8s95.8 43 95.8 95.8zM387.4 311c-30.2 0-54.8-24.6-54.8-54.8 0-30.2 24.6-54.8 54.8-54.8 30.2 0 54.8 24.6 54.8 54.8-.1 30.3-24.6 54.8-54.8 54.8zm0-82.1c-15.1 0-27.4 12.3-27.4 27.4 0 15.1 12.3 27.4 27.4 27.4 15.1 0 27.4-12.3 27.4-27.4 0-15.1-12.3-27.4-27.4-27.4z"
        fill="#47fdca"
        className="fill-000000"
      />
      <path
        d="M250.5 297.4c-37.7 0-68.4-30.7-68.4-68.4s30.7-68.4 68.4-68.4 68.4 30.7 68.4 68.4-30.7 68.4-68.4 68.4zm0-109.5c-22.6 0-41.1 18.4-41.1 41.1s18.4 41.1 41.1 41.1 41.1-18.4 41.1-41.1-18.5-41.1-41.1-41.1zM346.3 365.8h-27.4v-13.7c0-37.7 30.7-68.4 68.4-68.4s68.4 30.7 68.4 68.4h-27.4c0-22.6-18.4-41.1-41.1-41.1s-41.1 18.4-41.1 41.1v13.7zM182.1 365.8h-27.4v-13.7c0-22.6-18.4-41.1-41.1-41.1s-41.1 18.4-41.1 41.1H45.2c0-37.7 30.7-68.4 68.4-68.4s68.4 30.7 68.4 68.4v13.7z"
        fill="#47fdca"
        className="fill-000000"
      />
    </svg>

  );
};
export const ContactIcon = () => {
  return (
    <svg viewBox="0 0 24 24" className="w-9">
      <path
        d="M19 8h-2v3h-3v2h3v3h2v-3h3v-2h-3zM4 8a3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4 3.91 3.91 0 0 0-4 4zm6 0a1.91 1.91 0 0 1-2 2 1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2zM4 18a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v1h2v-1a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v1h2z"
        fill="#47fdca"
        className="fill-000000"
      />
    </svg>
  );
};
export const GroupIcon = () => {
  return (
    <svg className="w-9" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="40.5"
        cy={27}
        r={7}
        fill="none"
        stroke="#47fdca"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3px"
        className="stroke-000000"
      />
      <path
        d="M26.5 51.5c0-9.66 6.27-17.5 14-17.5s14 7.84 14 17.5Z"
        fill="none"
        stroke="#47fdca"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3px"
        className="stroke-000000"
      />
      <circle
        cx="23.5"
        cy="19.5"
        r={7}
        fill="none"
        stroke="#47fdca"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3px"
        className="stroke-000000"
      />
      <path
        d="M27.46 44H9.5c0-9.66 6.27-17.5 14-17.5 5 0 9.34 3.24 11.83 8.13"
        fill="none"
        stroke="#47fdca"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3px"
        className="stroke-000000"
      />
    </svg>
  );
};
export const MicrophoneIcon = () => {
  return (
    <svg fill='white' viewBox="0 0 32 32" className='w-6'>
      <g data-name="Layer 46" id="Layer_46">
        <path
          className="cls-1"
          d="M16,27A10,10,0,0,1,6,17a1,1,0,0,1,2,0,8,8,0,0,0,16,0,1,1,0,0,1,2,0A10,10,0,0,1,16,27Z"
        />
        <path
          className="cls-1"
          d="M16,31a1,1,0,0,1-1-1V26a1,1,0,0,1,2,0v4A1,1,0,0,1,16,31Z"
        />
        <path
          className="cls-1"
          d="M16,23a6,6,0,0,1-6-6V7A6,6,0,0,1,22,7V17A6,6,0,0,1,16,23ZM16,3a4,4,0,0,0-4,4V17a4,4,0,0,0,8,0V7A4,4,0,0,0,16,3Z"
        />
      </g>
    </svg>
  );
};
export const SearchInputIcon = () => {
  return (
    <svg
      className='w-5'
      fill='white'
      viewBox="0 0 512 512"
    >
      <path d="M344.5,298c15-23.6,23.8-51.6,23.8-81.7c0-84.1-68.1-152.3-152.1-152.3C132.1,64,64,132.2,64,216.3  c0,84.1,68.1,152.3,152.1,152.3c30.5,0,58.9-9,82.7-24.4l6.9-4.8L414.3,448l33.7-34.3L339.5,305.1L344.5,298z M301.4,131.2  c22.7,22.7,35.2,52.9,35.2,85c0,32.1-12.5,62.3-35.2,85c-22.7,22.7-52.9,35.2-85,35.2c-32.1,0-62.3-12.5-85-35.2  c-22.7-22.7-35.2-52.9-35.2-85c0-32.1,12.5-62.3,35.2-85c22.7-22.7,52.9-35.2,85-35.2C248.5,96,278.7,108.5,301.4,131.2z" />
    </svg>
  )
}