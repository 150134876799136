import React from 'react'
import Ananya from "../../images/Ananya.svg";
import user from "../../images/user.svg";
import offline from "../../images/offline.svg";
import logo from "../../images/logo.svg";
import { BackIcon, MediaIcon, MicrophoneIcon, PlusIcon, TickIcon } from '../../assets';

export const ChattingPage = () => {

  return (
    <React.Fragment>
      <div className='w-full md:w-[450px] mx-auto'>
        <div className='bg-dark-gray p-2 flex items-center gap-4'>
          <a href='/addfriend'>
            <BackIcon />
          </a>
          <a href='/chatting' className='flex gap-2 items-center'>
            <div className='relative w-9'>
              {/* <img src={Ananya} className='w-9 min-w-9 rounded-full' /> */}
              <img src={user} className='w-9 min-w-9 rounded-full' />
              <div className='absolute w-3 right-0 bottom-0'>
                <img src={logo} className='w-full rounded-full' />
                {/* <img src={offline} className='w-full rounded-full' /> */}
              </div>
            </div>
            <div className='w-full flex flex-col gap-[2px]'>
              <p className='text-xs text-green font-bold'>Ananya</p>
              <p className='text-[10px] text-gray-400'>3h7x2gf9kq</p>
            </div>
          </a>
        </div>

        <div className='px-5 bg-[#1e1e1e] chat-bg'>
          <p className='text-center text-sm pt-4 mb-4'>
            <span className='bg-[#313131] rounded-lg px-[10px] py-1'>Today</span>
          </p>

          <div className='conversation'>
            <div className='conversation-container'>
              <div className="message sent">
                What happened last night?
                <span className="metadata">
                  <span className="time">1:36 PM</span>
                  <span className="tick">
                    <TickIcon />
                  </span>
                </span>
              </div>

              <div className="message sent">
                <div className='media-item'>
                  <MediaIcon />
                  <div>IMG_0475</div>
                </div>
                <span className="metadata size">
                  <span className="time items-center gap-1 font-medium">2.4 MB
                    <div className='rounded-full bg-gray-500 w-1 h-1'></div>
                    png
                  </span>
                </span>
                <span className="metadata">
                  <span className="time">1:36 PM</span>
                  <span className="tick">
                    <TickIcon />
                  </span>
                </span>
              </div>

              <div className="message received">
                I was sick.
                <span className="metadata">
                  <span className="time">1:36 PM</span>
                </span>
              </div>

            </div>
          </div>

          <div className='grid chat-bg grid-cols-[24px_auto_24px] gap-3 fixed bottom-0 w-full left-0 bg-dark-gray px-4 py-3'>
            <button>
              <PlusIcon />
            </button>
            <input type='text' className='w-full outline-0 rounded-full border border-white px-4 py-[6px] text-sm text-white bg-dark-gray' />
            <button>
              <MicrophoneIcon />
            </button>
          </div>
        </div>


      </div>
    </React.Fragment>
  )
}
