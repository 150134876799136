import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import barcode from "../../images/barcode.svg";
import william from "../../images/william.svg";
import logo from "../../images/logo.svg";
import QrModal from '../../components/QrModal/QrModal';
import { EditIcon } from '../../assets';

export const Settings = () => {
  const { profile } = useSelector((state) => state.profileSetting);
  const navigate = useNavigate();
  const [showQrModal, setShowQrModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (profile) {
      setIsChecked(profile?.searchbyId);
    }
  }, [profile]);

  const handleToggle = async () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    const token = localStorage.getItem("authToken");
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/change-search-status`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Failed to change search status");
    }
    return await response.json();
  };

  return (
    <React.Fragment>
      {showQrModal && (<QrModal setShowQrModal={setShowQrModal} profile={profile} />)}
      <div>
        <div className='px-4'>
          <div className='pt-4 absolute'>
            <div onClick={() => navigate('/chathome')} className='text-green cursor-pointer font-semibold'>Back</div>
          </div>
          <p className='font-semibold pt-4 text-base text-center'>Privacy Settings</p>

          <div className='bg-dark-gray rounded-[10px] px-4 py-3 flex items-center justify-between mt-8'>
            <div className='flex gap-2 items-start'>
              <div className='relative min-w-9 max-w-9'>
                <img src={profile?.avatar || william} className='min-w-9 max-w-9 rounded-full' />
                <div className='absolute w-3 right-0 bottom-0'>
                  <img src={logo} className='w-full' />
                </div>
              </div>
              <div className='w-full'>
                <p className='text-sm text-white'>{profile?.displayName}</p>
                <p className='text-[10px] text-gray-400'>{profile?.uid?.split('-').pop()}</p>
              </div>
            </div>

            <div className='flex gap-3'>
              <a onClick={() => setShowQrModal(true)} className='cursor-pointer w-10 h-10 bg-icon flex justify-center items-center rounded-full'>
                <img src={barcode} className='w-[18px] h-[18px]' alt='Media' />
              </a>
              <a className='cursor-pointer w-10 h-10 bg-icon flex justify-center items-center rounded-full'>
                <EditIcon />
              </a>
            </div>
          </div>

          <div>
            <h1 className='text-white text-base mb-3 mt-8'>Privacy settings</h1>
            <div className='flex justify-between items-center bg-dark-gray rounded-md p-3'>
              <div>
                <p className='text-sm text-white'>User ID Searchability</p>
                <p className='text-[10px] text-white'>If turned off users will not be able to search you.</p>
              </div>

              <div className="flex">
                <input
                  type="checkbox"
                  id="switch"
                  checked={isChecked}
                  onChange={handleToggle}
                />
                <label className="toggle" htmlFor="switch">
                  Toggle
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
