import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userExists: null, // Will store true if user exists, false otherwise
    loading: false, // Loading state for the API call
    error: null, // To store any errors from the API
};

export const userSlice = createSlice({
    name: "userReducer",
    initialState,
    reducers: {
        checkUserExistsStart: (state) => {
            state.loading = true;
            state.error = null;
            state.userExists = null;
        },
        checkUserExistsSuccess: (state, action) => {
            state.loading = false;
            state.userExists = action.payload; // Assuming API returns { exists: true/false }
        },
        checkUserExistsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload; // Store error message
        },
    },
});

export const { checkUserExistsStart, checkUserExistsSuccess, checkUserExistsFailure } =
    userSlice.actions;
export default userSlice.reducer;
