import { takeLatest, call, put } from "redux-saga/effects";
import { updateProfileFailure, updateProfileStart, updateProfileSuccess } from "../reducers/userProfileSlice";

const updateProfileAPI = async (payload) => {
    const token = localStorage.getItem("authToken");
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/update-profile`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to update profile");
    }
    return await response.json();
};

function* handleUpdateProfile(action) {
    try {
        yield call(updateProfileAPI, action.payload); // Call API with profile data
        yield put(updateProfileSuccess()); // Dispatch success action
    } catch (error) {
        yield put(updateProfileFailure(error.message)); // Dispatch failure action
    }
}

export function* profileSaga() {
    yield takeLatest(updateProfileStart.type, handleUpdateProfile);
}
