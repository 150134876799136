import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { initiateSignin } from '../redux/reducers/auth';
import { DownIcon, LoadingIcon } from '../assets';
import logo from "../images/logo.svg";

export const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth, loading, error } = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth) {
      navigate("/username");
    }
  })

  const handleSubmit = (data) => {
    dispatch(initiateSignin({
      walletAddress: '0x239345d6b92907C7a0646007fd4aF60486b84F0211ab',
      user_device_id: 'abc123',
    }));
  };

  return (
    <React.Fragment>
      <div className='main-wrapper'>
        <div className='pt-4'>
          <a to='/' className='text-green font-semibold'>Cancel</a>
        </div>
        <h1 className='text-2xl font-bold text-center pt-12'>Welcome to </h1>
        <div className='flex justify-center flex-col items-center py-24'>
          <img className="w-28 rounded-[5px]" src={logo} alt="Media" />
          <p className='text-center font-bold text-base uppercase pt-1'>WOW Chat</p>
        </div>
        <p className='text-xs font-medium text-center'>
          Click below to get started!
          {error && <div className='error-message text-[12px] text-wow-red'>{(error)}</div>}
        </p>
        <div className='pt-4 flex justify-center'>
          <DownIcon />
        </div>
        {loading ?
          <button type='button' className='text-center block min-h-11 mt-7 mb-3 p-3 rounded-lg w-full bg-btn text-white text-base font-medium'>
            <LoadingIcon />
          </button> :
          <button onClick={() => handleSubmit()} className='text-center block min-h-11 mt-7 mb-3 p-3 rounded-lg w-full bg-btn text-white text-base font-medium'>
            Join Wow Chat
          </button>
        }
      </div>
    </React.Fragment>
  )
}
