import { all } from "@redux-saga/core/effects";
import {
    initiateSignInSaga, usernameCheckSaga,
} from "./authSagas";
import { userCheckSaga } from "./userSagas";
import { profileSaga } from "./userProfileSagas";
import { chatsSaga, contactListSaga, profileSettingSaga } from "./profileSettingSagas";
import { handleGroupCreation, initChatLoad, initGroupInfoUpdateSaga, initSendMsgStart } from "./chatSagas";

export default function* rootSaga() {
    yield all([
        initiateSignInSaga(),
        usernameCheckSaga(),
        userCheckSaga(),
        profileSaga(),
        profileSettingSaga(),
        contactListSaga(),
        chatsSaga(),
        handleGroupCreation(),
        initChatLoad(),
        initGroupInfoUpdateSaga(),
        initSendMsgStart(),
        // initChatLoad(),
        // initSendMsgStart(),
        // initFileUpload(),
        // initCallSaga(),
        // handleGroupCreation(),
        // // activeChatSwitch(),
        // initGroupInfoUpdateSaga(),
        // initAuthuserInfoUpdateSaga(),
    ]);
}
