import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/rootSaga";
import { authSlice } from "./reducers/auth";
import { userSlice } from "./reducers/userSlice";
import { profileSlice } from "./reducers/userProfileSlice";
import { profileSettingSlice } from "./reducers/profileSettingSlice";
import { chatSlice } from "./reducers/chat";

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Configure the Redux store
const store = configureStore({
    reducer: {
        auth: authSlice.reducer, // Add reducers here
        user: userSlice.reducer,
        profile: profileSlice.reducer,
        profileSetting: profileSettingSlice.reducer,
        chatState: chatSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware), // Replace thunk with saga
});

// Run the root saga
sagaMiddleware.run(rootSaga);

export default store;
