import { Route, Routes } from "react-router-dom";
import './App.css';
import { Home } from './components/Home';
import { Username } from './pages/Username/Username';
import { ChatHome } from './pages/ChatHome/ChatHome';
import { ChatList } from './pages/ChatList/ChatList';
import { Contacts } from './pages/Contacts/Contacts';
import { AddFriend } from './pages/AddFriend/AddFriend';
import { InviteFriend } from './pages/InviteFriend/InviteFriend';
import { Settings } from './pages/Settings/Settings';
import { ChattingPage } from './pages/ChattingPage/ChattingPage';
import CheckUser from "./pages/Username/CheckUser";

function App() {
  return (
    <Routes>
      <Route path="/" element={<CheckUser />} />
      <Route path="/join" element={<Home />} />
      <Route path="/chathome" element={<ChatHome />} />
      <Route path="/username" element={<Username />} />
      {/* <Route path="/chatlist" element={<ChatList />} /> */}
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/addfriend" element={<AddFriend />} />
      <Route path="/invitefriend" element={<InviteFriend />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/chattingpage" element={<ChattingPage />} />
    </Routes >
  );
}

export default App;
