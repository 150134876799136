import React from 'react'
import dots from "../../images/dots.png";
import plus from "../../images/plus.svg";
import { SearchIcon } from '../../assets';

export const SearchField = () => {

  return (
    <React.Fragment>
      <div className="group fields py-5">
        <div className="relative flex items-center">
          <input
            type="text"
            placeholder='Search name or user id'
            className="relative h-7 text-xs w-full border border-gray-400 rounded-full bg-[#2C2C2E] pr-4 pl-9 outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-[#2C2C2E] focus:ring-1 focus:ring-green focus:drop-shadow-lg"
          />
          <span className="absolute left-[10px] transition-all duration-200 ease-in-out group-focus-within:text-primary">
            <SearchIcon />
          </span>
        </div>

      </div>


    </React.Fragment>
  )
}
