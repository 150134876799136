"use client";
import React from 'react';
import logo from "../../images/logo.svg";
import imessage from "../../images/imessage.svg";
import telegram from "../../images/telegram.svg";
import whatsapp from "../../images/whatsapp.svg";
import mail from "../../images/mail.svg";
import cross from "../../images/cross.svg";
import copy from "../../images/copy.svg";
import karen from "../../images/karen.png";
import tong from "../../images/tong.png";
import tom from "../../images/tom.png";
import anie from "../../images/anie.png";


export default function InviteModal({ setShowInviteModal }) {
  return (
    <React.Fragment>
      {/* Invite Modal Starts Here */}
      <div className='app-modal'>
        <div
          className="wallet-wrapper justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-full h-full">
            {/*content*/}
            <div className="mt-10 absolute md:relative bottom-0 max-[768px]:w-full mx-auto border-0 rounded-tl-[30px] rounded-tr-[30px] md:rounded-[30px] flex flex-col w-[450px] bg-dark-gray outline-none focus:outline-none">
              {/*header*/}
              <button className='close-modal absolute right-[20px] top-[12px]' onClick={() => setShowInviteModal(false)}>
                <img src={cross} className='w-6' alt='Media' />
              </button>
              <div className='modal-content rounded-2xl px-[20px] py-3 mt-4'>
                <div className='mt-4 mb-11'>
                  <div className='flex items-start gap-2'>
                    <img src={logo} className='w-12' alt='Media' />
                    <div>
                      <h2 className='text-xl font-semibold'>Lets chat on Wow earn </h2>
                      <a href='/https://wowearn.com/' className='text-sm font-semibold'>wowearn.com</a>
                    </div>
                  </div>
                </div>
                <div className='mb-6'>
                  <div className='grid grid-cols-4 gap-6'>
                    <button className='flex justify-center items-center flex-col gap-2'>
                      <div className='relative w-[60px]'>
                        <img src={karen} className='w-[60px] rounded-full' />
                        <img src={logo} className='absolute bottom-0 right-0 w-5 rounded-full' />
                      </div>
                      <p className='text-xs text-center'>Karen</p>
                    </button>

                    <button className='flex justify-center items-center flex-col gap-2'>
                      <div className='relative w-[60px]'>
                        <img src={tong} className='w-[60px] rounded-full' />
                        <img src={logo} className='absolute bottom-0 right-0 w-5 rounded-full' />
                      </div>
                      <p className='text-xs text-center'>Tong</p>
                    </button>

                    <button className='flex justify-center items-center flex-col gap-2'>
                      <div className='relative w-[60px]'>
                        <img src={anie} className='w-[60px] rounded-full' />
                        <img src={logo} className='absolute bottom-0 right-0 w-5 rounded-full' />
                      </div>
                      <p className='text-xs text-center'>Liam</p>
                    </button>

                    <button className='flex justify-center items-center flex-col gap-2'>
                      <div className='relative w-[60px]'>
                        <img src={tom} className='w-[60px] rounded-full' />
                        <img src={logo} className='absolute bottom-0 right-0 w-5 rounded-full' />
                      </div>
                      <p className='text-xs text-center'>Tom</p>
                    </button>

                    <button className='flex justify-center items-center flex-col gap-2'>
                      <div className='relative min-w-[55px]'>
                        <img src={imessage} className='min-w-[55px] max-w-[55px]' />
                      </div>
                      <p className='text-xs text-center'>iMessage</p>
                    </button>

                    <button className='flex justify-center items-center flex-col gap-2'>
                      <div className='relative min-w-[55px]'>
                        <img src={telegram} className='min-w-[55px] max-w-[55px]' />
                      </div>
                      <p className='text-xs text-center'>Telegram</p>
                    </button>

                    <button className='flex justify-center items-center flex-col gap-2'>
                      <div className='relative min-w-[55px]'>
                        <img src={whatsapp} className='min-w-[55px] max-w-[55px]' />
                      </div>
                      <p className='text-xs text-center'>WhatsApp</p>
                    </button>

                    <button className='flex justify-center items-center flex-col gap-2'>
                      <div className='relative min-w-[55px]'>
                        <img src={mail} className='min-w-[55px] max-w-[55px]' />
                      </div>
                      <p className='text-xs text-center'>Mail</p>
                    </button>
                  </div>

                  <button className='mt-5 w-full bg-[#494848] rounded-[10px] px-6 py-3 flex justify-between items-center'>
                    <p className='text-xl font-semibold'>Copy</p>
                    <img src={copy} className='min-w-8 max-w-8' alt='Media' />
                  </button>

                </div>
                {/*body*/}

              </div>
            </div>

          </div>
        </div>
      </div>
      {/* Invite Modal Ends Here */}
    </React.Fragment>
  )
}
