import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    auth: null,
    loading: false,
    error: null,
    checkUserError: null,
    token: null,
};

export const authSlice = createSlice({
    name: "authReducer",
    initialState,
    reducers: {
        initiateSignin: (state) => {
            state.loading = true;
            state.error = null; 
            
        },
        signinSuccess: (state, action) => {
            state.loading = false;
            state.auth = action.payload;
            state.token = action.payload.accessToken;
            state.error = null;
        },

        signinFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload; // Store the error message
        },
        // Username check reducers
        checkUsername: (state) => {
            state.usernameCheckLoading = true;
            state.usernameAvailability = null;
            state.checkUserError = null;
        },
        checkUsernameSuccess: (state, action) => {
            state.usernameCheckLoading = false;
            state.usernameAvailability = action.payload.available;
        },
        checkUsernameFailure: (state, action) => {
            state.usernameCheckLoading = false;
            state.checkUserError = action.payload;
        },
        setAuthSuccess: (state, action) => {
            state.auth = action.payload;
            state.error = null;
            state.loading = false;
          },
          socketDisconnected: (state) => {
            state.socketStatus = false;
          },
          setAuthFailed: (state, action) => {
            state.auth = null;
            state.error = action.payload;
            state.loading = false;
            state.socketStatus = false;
          },
    },
});

export const {
    initiateSignin,
    signinSuccess,
    signinFailure,
    checkUsername,
    checkUsernameSuccess,
    checkUsernameFailure,
    setAuthSuccess,
    setAuthFailed,
    socketDisconnected,
} = authSlice.actions;
export default authSlice.reducer;
