import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updating: false,
    success: false,
    error: null,
    profile: null,
    loadingProfile: false,
    //contact
    loadingContactList: false,
    contactList: [],
    contactListError: null,
    //chat
    loadingChats: false,
    chats: [],
    chatsError: null,
};

export const profileSettingSlice = createSlice({
    name: "profileReducer",
    initialState,
    reducers: {
        updateProfileStart: (state) => {
            state.updating = true;
            state.success = false;
            state.error = null;
        },
        updateProfileSuccess: (state) => {
            console.log("Profile updated successfully", state);
            state.updating = false;
            state.success = true;
        },
        updateProfileFailure: (state, action) => {
            state.updating = false;
            state.error = action.payload;
        },
        resetProfileState: (state) => {
            state.updating = false;
            state.success = false;
            state.error = null;
        },
        fetchProfileStart: (state) => {
            state.loadingProfile = true;
            state.error = null;
        },
        fetchProfileSuccess: (state, action) => {
            state.loadingProfile = false;
            state.profile = action.payload?.user; // Update profile data
        },
        fetchProfileFailure: (state, action) => {
            state.loadingProfile = false;
            state.error = action.payload;
        },
        fetchContactListStart: (state) => {
            state.loadingContactList = true;
            state.contactListError = null; // Reset errors before fetching
        },
        fetchContactListSuccess: (state, action) => {
            state.loadingContactList = false;
            state.contactList = action.payload; // Store the fetched contact list data
        },
        fetchContactListFailure: (state, action) => {
            state.loadingContactList = false;
            state.contactListError = action.payload; // Store any error message
        },
        fetchChatsStart: (state) => {
            state.loadingChats = true;
            state.chatsError = null; // Reset errors before fetching
        },
        fetchChatsSuccess: (state, action) => {
            state.loadingChats = false;
            state.chats = action.payload?.data; // Store the fetched chats data
        },
        fetchChatsFailure: (state, action) => {
            state.loadingChats = false;
            state.chatsError = action.payload; // Store error message
        },
    },
});

export const {
    updateProfileStart,
    updateProfileSuccess,
    updateProfileFailure,
    resetProfileState,
    fetchProfileStart,
    fetchProfileSuccess,
    fetchProfileFailure,
    fetchContactListStart,
    fetchContactListSuccess,
    fetchContactListFailure,
    fetchChatsStart,
    fetchChatsSuccess,
    fetchChatsFailure,
} = profileSettingSlice.actions;

export default profileSettingSlice.reducer;
