import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Button, Modal, Box } from '@mui/material';
import getCroppedImg from './CropImage'; // Helper function for cropping

const CropModal = ({ open, onClose, imageToCrop, onCropSave }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropSave = async () => {
        try {
            const croppedImage = await getCroppedImg(imageToCrop, croppedAreaPixels);
            onCropSave(croppedImage); // Pass cropped image back to parent
            onClose(); // Close modal
        } catch (error) {
            console.error("Error cropping image:", error);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ width: '100%', height: '100%', position: 'relative', backgroundColor: '#000' }}>
                <Cropper
                    image={imageToCrop}
                    crop={crop}
                    zoom={zoom}
                    aspect={1} // Square aspect ratio
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                />
                <div className="absolute bottom-10 left-0 right-0 flex justify-center gap-8 p-4">
                    <button onClick={onClose} className='text-center block min-h-11 mt-9 mb-3 p-3 rounded-lg w-full bg-white text-black text-base hover:bg-hover  font-medium'>
                        Cancel
                    </button>
                    <button onClick={handleCropSave} className='text-center block min-h-11 mt-9 mb-3 p-3 rounded-lg w-full bg-btn hover:bg-hover text-black text-base font-medium'>
                        Save
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default CropModal;
