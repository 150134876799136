import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkUserExistsStart } from "../../redux/reducers/userSlice";
import { LoadingIcon } from "../../assets";

const CheckUser = () => {
    const [walletAddress, setWalletAddress] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userExists, loading, error } = useSelector((state) => state.user);

    useEffect(() => {
        handleCheckUser()
    }, [])

    console.log(userExists)
    useEffect(() => {
        if (userExists?.user?._id) {
            if (userExists.accessToken) {
                localStorage.setItem("authToken", userExists.accessToken); // Save token
                navigate("/chathome");
            }
        } else if (userExists?.userExists === false) {
            navigate("/join"); // Redirect to Home if user does not exist
        }
    }, [userExists, navigate]);

    const handleCheckUser = () => {
        dispatch(checkUserExistsStart("0x239345d6b92907C7a0646007fd4aF60486b84F0211ab")); // Dispatch action to check user existence
    };

    return (
        <div className="flex items-center justify-center h-screen">
            <LoadingIcon />
        </div>
    );
};

export default CheckUser;
